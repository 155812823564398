import closeFill from '@iconify/icons-eva/close-fill'
import { Icon } from '@iconify/react'
import { LoadingButton } from '@mui/lab'
// material
import { FormHelperText, Stack } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { TextFieldElement } from 'react-hook-form-mui'
import { useNavigate } from 'react-router-dom'
import shallow from 'zustand/shallow'

import useAuthError from '../../../lib/hooks/useAuthError'
import { useAuthState } from '../../../lib/state/authState'
import { MIconButton } from '../../../minimal_theme/components/@material-extend'
import { PATH_AUTH } from '../../../routes/paths'
import AuthErrorAlert from '../AuthErrorAlert'

// routes
// utils

// ----------------------------------------------------------------------

// eslint-disable-next-line consistent-return
function maxLength(object: any) {
    if (object.target.value.length > object.target.maxLength) {
        return (object.target.value = object.target.value.slice(
            0,
            object.target.maxLength
        ))
    }
}

type CodeConfirmForm = { code: string }
export default function VerifyCodeForm() {
    const confirmSignUp = useAuthState((state) => state.confirmSignUp, shallow)
    const navigate = useNavigate()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const { handleSubmit, control } = useForm<CodeConfirmForm>()
    const [error, setError] = useAuthError()
    const [submitting, setSubmitting] = useState(false)

    const onSubmit: SubmitHandler<CodeConfirmForm> = async (data) => {
        setSubmitting(true)
        try {
            await confirmSignUp(data.code)
            enqueueSnackbar('Your Account is verified', {
                variant: 'success',
                action: (key) => (
                    <MIconButton
                        size="small"
                        onClick={() => closeSnackbar(key)}
                    >
                        <Icon icon={closeFill} />
                    </MIconButton>
                )
            })
            navigate(PATH_AUTH.login)
        } catch (e) {
            setError(e)
        }
        setSubmitting(false)
    }

    return (
        <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
            <Stack direction="row" spacing={2} justifyContent="center">
                <AuthErrorAlert error={error} />
                <TextFieldElement
                    name={'code'}
                    control={control}
                    inputProps={{
                        placeholder: 'Code',
                        inputProps: {
                            maxLength: 6
                        },
                        onInput: maxLength
                    }}
                />
            </Stack>

            <FormHelperText error={!!error} style={{ textAlign: 'right' }}>
                {error && 'Code is required'}
            </FormHelperText>

            <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                sx={{ mt: 3 }}
                loading={submitting}
            >
                Verify
            </LoadingButton>
        </form>
    )
}
