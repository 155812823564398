import { Box, Button, Drawer, Link, Stack, Typography } from '@mui/material'
// material
import { alpha } from '@mui/material/styles'
import { useEffect } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import shallow from 'zustand/shallow'

import ClubSelector from '../../../components/club/ClubSelector'
import MyAvatar from '../../../components/MyAvatar'
import { useAuthState } from '../../../lib/state/authState'
import { useGlobalStore } from '../../../lib/state/globalState'
import { MHidden } from '../../../minimal_theme/components/@material-extend'
import Logo from '../../../minimal_theme/components/Logo'
import NavSection from '../../../minimal_theme/components/NavSection'
import Scrollbar from '../../../minimal_theme/components/Scrollbar'
import useCollapseDrawer from '../../../minimal_theme/hooks/useCollapseDrawer'
import { PATH_DASHBOARD } from '../../../routes/paths'
import { IconCollapse } from './IconCollapse'
import useSidebarConfig, {
    AccountStyle,
    COLLAPSE_WIDTH,
    DRAWER_WIDTH,
    RootStyle
} from './SidebarConfig'

export default function DashboardSidebar() {
    const { pathname } = useLocation()
    const [user, signOut] = useAuthState(
        (state) => [state.user, state.signOut],
        shallow
    )
    const [isOpenSidebar, setSidebarOpen] = useGlobalStore(
        (state) => [state.sidebarOpen, state.setSidebarOpen],
        shallow
    )
    const sidebarConfig = useSidebarConfig()

    const {
        isCollapse,
        collapseClick,
        collapseHover,
        onToggleCollapse,
        onHoverEnter,
        onHoverLeave
    } = useCollapseDrawer()

    useEffect(() => {
        if (isOpenSidebar) {
            onCloseSidebar()
        }
    }, [pathname])
    const onCloseSidebar = () => {
        setSidebarOpen(false)
    }

    const renderContent = (
        <Scrollbar
            sx={{
                height: 1,
                '& .simplebar-content': {
                    height: 1,
                    display: 'flex',
                    flexDirection: 'column'
                }
            }}
        >
            <Stack
                spacing={3}
                sx={{
                    px: 2.5,
                    pt: 3,
                    pb: 2,
                    ...(isCollapse && {
                        alignItems: 'center'
                    })
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Box
                        component={RouterLink}
                        to="/"
                        sx={{ display: 'inline-flex' }}
                    >
                        <Logo />
                    </Box>
                    <ClubSelector />

                    <MHidden width="lgDown">
                        {!isCollapse && (
                            <IconCollapse
                                onToggleCollapse={onToggleCollapse}
                                collapseClick={collapseClick}
                            />
                        )}
                    </MHidden>
                </Stack>

                {isCollapse ? (
                    <MyAvatar sx={{ mx: 'auto', mb: 2 }} />
                ) : (
                    <Link
                        underline="none"
                        component={RouterLink}
                        to={PATH_DASHBOARD.general.settings}
                    >
                        <AccountStyle>
                            <MyAvatar />
                            <Box sx={{ ml: 2 }}>
                                <Typography
                                    variant="subtitle2"
                                    sx={{ color: 'text.primary' }}
                                >
                                    {user.username}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{ color: 'text.secondary' }}
                                >
                                    {user?.role}
                                </Typography>
                            </Box>
                        </AccountStyle>
                    </Link>
                )}
            </Stack>

            <NavSection
                sx={{
                    '& .MuiListItemText-root': {
                        textTransform: 'uppercase'
                    }
                }}
                navConfig={sidebarConfig}
                isShow={!isCollapse}
            />

            <Button
                onClick={async () => {
                    await signOut()
                }}
            >
                logout
            </Button>

            <Box sx={{ flexGrow: 1 }} />
        </Scrollbar>
    )

    return (
        <RootStyle
            sx={{
                width: {
                    lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH
                },
                ...(collapseClick && {
                    position: 'absolute'
                })
            }}
        >
            <MHidden width="lgUp">
                <Drawer
                    open={isOpenSidebar}
                    onClose={onCloseSidebar}
                    PaperProps={{
                        sx: { width: DRAWER_WIDTH }
                    }}
                >
                    {renderContent}
                </Drawer>
            </MHidden>

            <MHidden width="lgDown">
                <Drawer
                    open
                    variant="persistent"
                    onMouseEnter={onHoverEnter}
                    onMouseLeave={onHoverLeave}
                    PaperProps={{
                        sx: {
                            width: DRAWER_WIDTH,
                            bgcolor: 'background.default',
                            ...(isCollapse && {
                                width: COLLAPSE_WIDTH
                            }),
                            ...(collapseHover && {
                                borderRight: 0,
                                backdropFilter: 'blur(6px)',
                                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                                boxShadow: (theme) => theme.customShadows.z20,
                                bgcolor: (theme) =>
                                    alpha(
                                        theme.palette.background.default,
                                        0.88
                                    )
                            })
                        }
                    }}
                >
                    {renderContent}
                </Drawer>
            </MHidden>
        </RootStyle>
    )
}
