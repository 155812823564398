export const removeNullsOfObject = (obj: any) => {
    if (obj === null) {
        return undefined
    }
    if (typeof obj === 'object') {
        for (const key in obj) {
            obj[key] = removeNullsOfObject(obj[key])
        }
    }
    return obj
}
