// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
    return `${root}${sublink}`
}

const ROOTS_AUTH = '/auth'
const ROOTS_DASHBOARD = '/'

// ----------------------------------------------------------------------

export const PATH_AUTH = {
    root: ROOTS_AUTH,
    login: path(ROOTS_AUTH, '/login'),
    loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
    register: path(ROOTS_AUTH, '/register'),
    registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
    resetPassword: path(ROOTS_AUTH, '/reset-password'),
    verify: path(ROOTS_AUTH, '/verify')
}

export const PATH_PAGE = {
    comingSoon: '/coming-soon',
    maintenance: '/maintenance',
    pricing: '/pricing',
    payment: '/payment',
    about: '/about-us',
    contact: '/contact-us',
    faqs: '/faqs',
    page404: '/404',
    page500: '/500',
    pageNotPermitted: '/not-permitted',
    components: '/components'
}

export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    general: {
        teams: path(ROOTS_DASHBOARD, 'teams'),
        settings: path(ROOTS_DASHBOARD, 'settings')
    },
    exercise: {
        list: path(ROOTS_DASHBOARD, 'exercises'),
        view: path(ROOTS_DASHBOARD, 'exercise/'), // add :id
        create: path(ROOTS_DASHBOARD, 'exercise/create'),
        update: path(ROOTS_DASHBOARD, 'exercise/update/') // add :id
    },
    club: {
        list: path(ROOTS_DASHBOARD, 'clubs'),
        update: path(ROOTS_DASHBOARD, 'club/update/'), // add :id
        create: path(ROOTS_DASHBOARD, 'club/create')
    }
}

export const PATH_DOCS = 'https://planet.training'
