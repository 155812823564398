import create from 'zustand'

type HomeState = {
    sidebarOpen: boolean
    setSidebarOpen: (bool: boolean) => void
    selected: string[]
    toggleSelectedExercises: (id: string) => void
    unsetSelected: () => void
}
export const useGlobalStore = create<HomeState>((set) => ({
    selected: [],
    sidebarOpen: false,
    setSidebarOpen: (bool) => set((state) => ({ sidebarOpen: bool })),
    unsetSelected: () => set((state) => ({ selected: [] })),
    toggleSelectedExercises: (id) =>
        set((state) => {
            let selected = [...state.selected]
            if (selected.find((i) => i === id)) {
                selected = selected.filter((i) => i !== id)
            } else {
                selected.push(id)
            }
            return {
                selected: selected
            }
        })
}))
