import { devtoolsExchange } from '@urql/devtools'
import { FC } from 'react'
import { createClient, dedupExchange, fetchExchange, Provider } from 'urql'

import { uqlAuthExchange } from './urqlAuthExchange'
import { offlineCache } from './urqlOfflineCache'

const initUrql = () => {
    const gqlClient = createClient({
        requestPolicy: 'cache-and-network',
        url: 'https://planet-training.hasura.app/v1/graphql',
        exchanges: [
            devtoolsExchange,
            dedupExchange,
            // errorExchange({
            //     onError: (error: CombinedError, operation: Operation) => {
            //         console.error('An error!', error)
            //     }
            // }),
            offlineCache,
            uqlAuthExchange,
            fetchExchange
        ]
    })
    return gqlClient
}

export const gqlClient = initUrql()

const UrqlProvider: FC = ({ children }) => {
    // const client = useMemo(() => {
    //     return initUrql()
    // }, [])
    return <Provider value={gqlClient}>{children}</Provider>
}
export default UrqlProvider
