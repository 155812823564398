import { LoadingButton } from '@mui/lab'
import { useState } from 'react'
import shallow from 'zustand/shallow'

import { useAuthState } from '../../lib/state/authState'

export default function ResendVerificationButton() {
    const [loading, setLoading] = useState<boolean>(false)
    const resendSignUp = useAuthState((state) => state.resendSignUp, shallow)

    return (
        <LoadingButton
            variant={'text'}
            loading={loading}
            onClick={async () => {
                setLoading(true)
                try {
                    await resendSignUp()
                } catch (e) {
                    console.error(e)
                }
                setLoading(false)
            }}
        >
            Resend code
        </LoadingButton>
    )
}
