import { ListItemButton, ListItemButtonProps } from '@mui/material'
import { FC } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'

type ListItemRouterProps = ListItemButtonProps & {
    to: NavLinkProps['to']
}
const ListItemRouter: FC<ListItemRouterProps> = (props) => {
    // @ts-ignore
    return <ListItemButton component={NavLink} {...props} />
}

export default ListItemRouter
