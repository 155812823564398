// material
import { styled, useTheme } from '@mui/material/styles'
import { Outlet } from 'react-router-dom'

// hooks
import useCollapseDrawer from '../../minimal_theme/hooks/useCollapseDrawer'
//
import DashboardSidebar from './sidebar/DashboardSidebar'

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64
const APP_BAR_DESKTOP = 92

const RootStyle = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden'
})

const MainStyle = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: APP_BAR_MOBILE + 24,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
        paddingTop: APP_BAR_DESKTOP + 24,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
}))

// ----------------------------------------------------------------------

export default function DashboardLayout() {
    const theme = useTheme()
    const { collapseClick } = useCollapseDrawer()

    return (
        <RootStyle>
            <DashboardSidebar />
            <MainStyle
                sx={{
                    transition: theme.transitions.create('margin', {
                        duration: theme.transitions.duration.complex
                    }),
                    ...(collapseClick && {
                        ml: '102px'
                    })
                }}
            >
                <Outlet />
            </MainStyle>
        </RootStyle>
    )
}
