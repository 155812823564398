// routes
// components
import listFill from '@iconify/icons-eva/list-fill'
import peopleFill from '@iconify/icons-eva/people-fill'
import settingsFill from '@iconify/icons-eva/settings-fill'
import { Icon } from '@iconify/react'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'

import { PATH_DASHBOARD } from '../../../routes/paths'

export const DRAWER_WIDTH = 280
export const COLLAPSE_WIDTH = 102

export default function useSidebarConfig() {
    const { t } = useTranslation()
    return [
        {
            subheader: 'Tool',
            items: [
                {
                    title: t('exercises'),
                    path: PATH_DASHBOARD.exercise.list,
                    icon: <Icon icon={listFill} />
                },
                {
                    title: t('teams_players'),
                    path: PATH_DASHBOARD.general.teams,
                    icon: <Icon icon={peopleFill} />
                },
                {
                    title: t('clubs'),
                    path: PATH_DASHBOARD.club.list,
                    icon: <Icon icon={peopleFill} />
                },
                {
                    title: t('settings'),
                    path: PATH_DASHBOARD.general.settings,
                    icon: <Icon icon={settingsFill} />
                }
            ]
        }
    ]
}
export const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        transition: theme.transitions.create('width', {
            duration: theme.transitions.duration.complex
        })
    }
}))
export const AccountStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: theme.shape.borderRadiusSm,
    backgroundColor: theme.palette.grey[500_12]
}))
