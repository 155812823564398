import { ReactNode, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import shallow from 'zustand/shallow'

import UserClubSubscribe from '../components/club/UserClubSubscribe'
import { useAuthState } from '../lib/state/authState'
// hooks
// import useAuth from '../hooks/useAuth'
// pages
import Login from '../pages/authentication/Login'
import VerifyCode from '../pages/authentication/VerifyCode'

// ----------------------------------------------------------------------

type AuthGuardProps = {
    children: ReactNode
}

export default function AuthGuard({ children }: AuthGuardProps) {
    const user = useAuthState((state) => state.user, shallow)
    const { pathname } = useLocation()
    const [requestedLocation, setRequestedLocation] = useState<string | null>(
        null
    )

    if (!user?.jwtToken) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname)
        }
        return <Login />
    }

    if (!user.confirmed) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname)
        }
        return <VerifyCode />
    }

    if (requestedLocation && pathname !== requestedLocation) {
        setRequestedLocation(null)
        return <Navigate to={requestedLocation} />
    }

    return <UserClubSubscribe>{children}</UserClubSubscribe>
}
