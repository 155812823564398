import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
// import ChainedBackend from 'i18next-chained-backend'
import HttpBackend from 'i18next-http-backend'
// import LocalStorageBackend from 'i18next-localstorage-backend'
import { initReactI18next } from 'react-i18next'

export const defaultNS = 'common'

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // detect user language
    .use(LanguageDetector)
    // .use(ChainedBackend)
    .use(HttpBackend)

    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'en',
        // react: {
        //   useSuspense: true // maybe change later on
        // },
        lng: localStorage.getItem('i18nextLng') || 'en',
        ns: ['common'],
        debug: true,
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        },
        defaultNS
        // backend: {
        //   backends:
        //     process.env.NODE_ENV === 'production'
        //       ? [LocalStorageBackend, HttpBackend]
        //       : [HttpBackend],
        //   backendOptions: [
        //     {
        //       expirationTime: 1 * 24 * 60 * 60 * 1000 // 1 days
        //     },
        //     {
        //       loadPath: '/locales/{{lng}}/{{ns}}.json'
        //     }
        //   ]
        // }
    })

export default i18n
