import { useState } from 'react'

import { AuthError } from '../../pages/authentication/authenticationType'

export default function useAuthError() {
    const [error, setError] = useState<AuthError | undefined | any>()
    // consider to show error notification
    if (error) {
        console.error(error)
    }
    return [error, setError]
}
