// hooks
import shallow from 'zustand/shallow'

import { useAuthState } from '../lib/state/authState'
//
import { MAvatar } from '../minimal_theme/components/@material-extend'
import { MAvatarProps } from '../minimal_theme/components/@material-extend/MAvatar'
import createAvatar from '../minimal_theme/utils/createAvatar'

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: MAvatarProps) {
    const user = useAuthState((state) => state.user, shallow)

    const currentName =
        user.displayName || `${user.first_name} ${user.last_name}`
    return (
        <MAvatar
            src={user.photoURL}
            alt={currentName}
            color={user.photoURL ? 'default' : createAvatar(currentName).color}
            {...other}
        >
            {createAvatar(currentName).name}
        </MAvatar>
    )
}
