import editFill from '@iconify/icons-eva/edit-fill'
import {
    Avatar,
    Button,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Menu
} from '@mui/material'
import {
    bindMenu,
    bindTrigger,
    usePopupState
} from 'material-ui-popup-state/hooks'
import { useEffect } from 'react'
import shallow from 'zustand/shallow'

import { avatarString } from '../../lib/helpers/avatarString'
import { useAuthState } from '../../lib/state/authState'
import { useClubState } from '../../lib/state/clubState'
import { PATH_DASHBOARD } from '../../routes/paths'
import IconButtonRouter from '../generic/link/IconButtonRouter'
import ListItemRouter from '../generic/link/ListItemRouter'

export default function ClubSelector() {
    const [getClubs] = useAuthState((state) => [state.getClubs], shallow)
    const allClubs = getClubs()
    const { selectedClub, setClub } = useClubState(
        (state) => ({
            setClub: state.setClub,
            selectedClub: state.selectedClub
        }),
        shallow
    )
    const popupState = usePopupState({
        variant: 'popover',
        popupId: 'clubSelectMenu'
    })

    const currentClubId = selectedClub?.id
    useEffect(() => {
        if (allClubs?.length) {
            const setThisClub = () => {
                const first = allClubs[0]
                setClub({
                    id: first.id,
                    name: first.name,
                    sport: first.sport
                })
            }
            if (!currentClubId) {
                setThisClub()
            } else if (
                currentClubId &&
                !allClubs.some((i) => i.id === currentClubId)
            ) {
                setThisClub()
            }
        }
    }, [currentClubId, allClubs, setClub])
    return (
        <>
            <Button variant="contained" {...bindTrigger(popupState)}>
                {selectedClub?.name || selectedClub?.id}
            </Button>
            <Menu
                {...bindMenu(popupState)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                <List>
                    {allClubs?.map((club) => (
                        <ListItem
                            button
                            key={club.id}
                            onClick={() => {
                                setClub({
                                    id: club.id,
                                    name: club.name,
                                    sport: club.sport
                                })
                            }}
                        >
                            <ListItemAvatar>
                                <Avatar>{avatarString(club.name)}</Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={club.name}
                                secondary={club.sport}
                            />
                        </ListItem>
                    ))}
                    <ListItemRouter to={PATH_DASHBOARD.club.list}>
                        <ListItemText
                            primary={'Overview'}
                            secondary={'Show all your clubs'}
                        />
                    </ListItemRouter>
                </List>
            </Menu>
        </>
    )
}
