import { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import shallow from 'zustand/shallow'

import { useAuthState } from '../lib/state/authState'
// hooks
// import useAuth from '../hooks/useAuth'
// routes
import { PATH_AUTH, PATH_DASHBOARD } from '../routes/paths'

// ----------------------------------------------------------------------

type GuestGuardProps = {
    children: ReactNode
}

export default function GuestGuard({ children }: GuestGuardProps) {
    const user = useAuthState((state) => state.user, shallow)

    if (user?.username && !user.confirmed) {
        return <Navigate to={PATH_AUTH.verify} />
    }

    if (user?.jwtToken) {
        return <Navigate to={PATH_DASHBOARD.root} />
    }

    return <>{children}</>
}
