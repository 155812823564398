import create from 'zustand'
import { persist } from 'zustand/middleware'

import { UserClubFragment } from '../urql/__generated__/generated-schema'

type ClubState = {
    selectedClub?: UserClubFragment
    setClub: (club: UserClubFragment) => void
}

export const useClubState = create<ClubState>(
    persist(
        (set) => ({
            setClub: (club) => set({ selectedClub: club })
        }),
        {
            name: 'club-state'
        }
    )
)
