// mock api
// import './minimal_theme/_apis_'
// highlight
// import './minimal_theme/utils/highlight'
// scroll bar
import 'simplebar/src/simplebar.css'
// editor
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
// redux
// i18n
import './i18n'
// AWS
import './lib/aws/AmplifyConfig'

// map
// import 'mapbox-gl/dist/mapbox-gl.css'
// lightbox
// import 'react-image-lightbox/style.css'
// editor
// import 'react-quill/dist/quill.snow.css'
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
// slick-carousel
// import 'slick-carousel/slick/slick.css'
// import 'slick-carousel/slick/slick-theme.css'
// lazy image
// import 'lazysizes'
// import 'lazysizes/plugins/attrchange/ls.attrchange'
// import 'lazysizes/plugins/object-fit/ls.object-fit'
// import 'lazysizes/plugins/parent-fit/ls.parent-fit'
// material
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import React from 'react'
import ReactDOM from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'

import AppShell from './AppShell'
// import { AuthProvider } from './contexts/AwsCognitoContext';
// import { AuthProvider } from './contexts/Auth0Context';
// import { AuthProvider } from './contexts/FirebaseContext';
//
// components
import { CollapseDrawerProvider } from './minimal_theme/contexts/CollapseDrawerContext'
// Check our docs
// https://docs-minimals.vercel.app/authentication/ts-version
// import { AuthProvider } from './minimal_theme/contexts/JWTContext'
// contexts
import { SettingsProvider } from './minimal_theme/contexts/SettingsContext'

// ----------------------------------------------------------------------

ReactDOM.render(
    <React.StrictMode>
        <HelmetProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SettingsProvider>
                    <CollapseDrawerProvider>
                        <BrowserRouter>
                            <AppShell />
                        </BrowserRouter>
                    </CollapseDrawerProvider>
                </SettingsProvider>
            </LocalizationProvider>
        </HelmetProvider>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
