import { ElementType, lazy, Suspense } from 'react'
import { Navigate, useLocation, useRoutes } from 'react-router-dom'

import AuthGuard from '../guards/AuthGuard'
// guards
import GuestGuard from '../guards/GuestGuard'
import DashboardLayout from '../layouts/dashboard'
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../minimal_theme/components/LoadingScreen'
import LogoOnlyLayout from '../minimal_theme/layouts/LogoOnlyLayout'
// layouts

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) =>
    function LoadableComponent(props: any) {
        const { pathname } = useLocation()
        const isDashboard = pathname.includes('/dashboard')

        return (
            <Suspense
                fallback={
                    <LoadingScreen
                        sx={{
                            ...(!isDashboard && {
                                top: 0,
                                left: 0,
                                width: 1,
                                zIndex: 9999,
                                position: 'fixed'
                            })
                        }}
                    />
                }
            >
                <Component {...props} />
            </Suspense>
        )
    }

export default function Router() {
    return useRoutes([
        {
            path: '/',
            element: (
                <AuthGuard>
                    <DashboardLayout />
                </AuthGuard>
            ),
            children: [
                { path: '/', element: <Navigate to={'/exercises'} /> },
                {
                    path: 'exercises',
                    element: <Exercises />
                },
                {
                    path: 'exercise/:id',
                    element: <ExerciseView />
                },
                { path: 'exercise/update/:id', element: <ExerciseEdit /> },
                { path: 'exercise/create', element: <ExerciseEdit /> },
                { path: 'teams', element: <TeamsPlayers /> },
                { path: 'settings', element: <Settings /> },
                { path: 'club/update/:id', element: <ClubEdit /> },
                { path: 'club/create', element: <ClubEdit /> },
                { path: 'clubs', element: <Clubs /> }
                // { path: 'contact-us', element: <Contact /> },
                // { path: 'contact-us', element: <Contact /> },
                // { path: 'faqs', element: <Faqs /> },
            ]
        },
        {
            path: 'auth',
            children: [
                {
                    path: 'login',
                    element: (
                        <GuestGuard>
                            <Login />
                        </GuestGuard>
                    )
                },
                {
                    path: 'register',
                    element: (
                        <GuestGuard>
                            <Register />
                        </GuestGuard>
                    )
                },
                { path: 'reset-password', element: <ResetPassword /> },
                { path: 'verify', element: <VerifyCode /> }
            ]
        },
        // Main Routes
        {
            path: '*',
            element: <LogoOnlyLayout />,
            children: [
                // { path: 'coming-soon', element: <ComingSoon /> },
                // { path: 'maintenance', element: <Maintenance /> },

                // { path: 'pricing', element: <Pricing /> },
                // { path: 'payment', element: <Payment /> },
                { path: '500', element: <Page500 /> },
                { path: '404', element: <NotFound /> },
                { path: 'not-permitted', element: <NotPermitted /> },
                { path: '*', element: <Navigate to="/404" replace /> }
            ]
        },

        { path: '*', element: <Navigate to="/404" replace /> }
    ])
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')))
const Register = Loadable(
    lazy(() => import('../pages/authentication/Register'))
)
const ResetPassword = Loadable(
    lazy(() => import('../pages/authentication/ResetPassword'))
)
const VerifyCode = Loadable(
    lazy(() => import('../pages/authentication/VerifyCode'))
)
// Dashboard
// const GeneralApp = Loadable(
//   lazy(() => import('../minimal_theme/pages/dashboard/GeneralApp'))
// )
// const GeneralEcommerce = Loadable(
//   lazy(() => import('../pages/dashboard/GeneralEcommerce'))
// )
// const GeneralAnalytics = Loadable(
//   lazy(() => import('../pages/dashboard/GeneralAnalytics'))
// )
// const GeneralBanking = Loadable(
//   lazy(() => import('../pages/dashboard/GeneralBanking'))
// )
// const GeneralBooking = Loadable(
//   lazy(() => import('../pages/dashboard/GeneralBooking'))
// )
// const EcommerceShop = Loadable(
//   lazy(() => import('../pages/dashboard/EcommerceShop'))
// )
// const EcommerceProductDetails = Loadable(
//   lazy(() => import('../pages/dashboard/EcommerceProductDetails'))
// )
// const EcommerceProductList = Loadable(
//   lazy(() => import('../pages/dashboard/EcommerceProductList'))
// )
// const EcommerceProductCreate = Loadable(
//   lazy(() => import('../pages/dashboard/EcommerceProductCreate'))
// )
// const EcommerceCheckout = Loadable(
//   lazy(() => import('../pages/dashboard/EcommerceCheckout'))
// )
// const EcommerceInvoice = Loadable(
//   lazy(() => import('../pages/dashboard/EcommerceInvoice'))
// )
// const BlogPosts = Loadable(
//   lazy(() => import('../minimal_theme/pages/dashboard/BlogPosts'))
// )
// const BlogPost = Loadable(
//   lazy(() => import('../minimal_theme/pages/dashboard/BlogPost'))
// )
// const BlogNewPost = Loadable(
//   lazy(() => import('../minimal_theme/pages/dashboard/BlogNewPost'))
// )
// const UserProfile = Loadable(
//     lazy(() => import('../minimal_theme/pages/dashboard/UserProfile'))
// )

// const UserCards = Loadable(
//   lazy(() => import('../minimal_theme/pages/dashboard/UserCards'))
// )
// const UserList = Loadable(
//   lazy(() => import('../minimal_theme/pages/dashboard/UserList'))
// )
// const UserAccount = Loadable(
//   lazy(() => import('../minimal_theme/pages/dashboard/UserAccount'))
// )
// const UserCreate = Loadable(
//   lazy(() => import('../minimal_theme/pages/dashboard/UserCreate'))
// )
// const Chat = Loadable(
//   lazy(() => import('../minimal_theme/pages/dashboard/Chat'))
// )
// const Mail = Loadable(
//   lazy(() => import('../minimal_theme/pages/dashboard/Mail'))
// )
// const Calendar = Loadable(
//   lazy(() => import('../minimal_theme/pages/dashboard/Calendar'))
// )
// const Kanban = Loadable(
//   lazy(() => import('../minimal_theme/pages/dashboard/Kanban'))
// )
// Main
// const LandingPage = Loadable(
//   lazy(() => import('../minimal_theme/pages/LandingPage'))
// )
// const About = Loadable(lazy(() => import('../minimal_theme/pages/About')))
// const Contact = Loadable(lazy(() => import('../minimal_theme/pages/Contact')))
// const Faqs = Loadable(lazy(() => import('../minimal_theme/pages/Faqs')))
// const ComingSoon = Loadable(
//   lazy(() => import('../minimal_theme/pages/ComingSoon'))
// )
// const Maintenance = Loadable(
//   lazy(() => import('../minimal_theme/pages/Maintenance'))
// )

// const Pricing = Loadable(lazy(() => import('../minimal_theme/pages/Pricing')))
// const Payment = Loadable(lazy(() => import('../minimal_theme/pages/Payment')))
const Page500 = Loadable(lazy(() => import('../minimal_theme/pages/Page500')))
const NotFound = Loadable(lazy(() => import('../minimal_theme/pages/Page404')))
const NotPermitted = Loadable(lazy(() => import('../pages/PageNotPermitted')))

const Exercises = Loadable(lazy(() => import('../pages/Exercises')))
const TeamsPlayers = Loadable(lazy(() => import('../pages/TeamsPlayers')))
const Settings = Loadable(lazy(() => import('../pages/Settings')))
const ExerciseEdit = Loadable(lazy(() => import('../pages/ExerciseEdit')))
const ExerciseView = Loadable(lazy(() => import('../pages/ExerciseView')))
const ClubEdit = Loadable(lazy(() => import('../pages/ClubEdit')))
const Clubs = Loadable(lazy(() => import('../pages/Clubs')))
