import closeFill from '@iconify/icons-eva/close-fill'
import { Icon } from '@iconify/react'
import { LoadingButton } from '@mui/lab'
// material
import { Link, Stack } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import {
    CheckboxElement,
    PasswordElement,
    TextFieldElement
} from 'react-hook-form-mui'
import { Link as RouterLink } from 'react-router-dom'
import shallow from 'zustand/shallow'

import useAuthError from '../../../lib/hooks/useAuthError'
import { useAuthState } from '../../../lib/state/authState'
//
import { MIconButton } from '../../../minimal_theme/components/@material-extend'
// hooks
// import useAuth from '../../../hooks/useAuth'
// routes
import { PATH_AUTH } from '../../../routes/paths'
import StackDefault from '../../defaultWrappers/StackDefault'
import AuthErrorAlert from '../AuthErrorAlert'

// ----------------------------------------------------------------------
type InitialValues = {
    email: string
    password: string
    remember: boolean
    afterSubmit?: string
}

type LoginFormTypes = {
    username: string
    password: string
}

export default function LoginForm() {
    // const { login } = useAuth()
    const login = useAuthState((state) => state.login, shallow)

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const [isSubmitting, setSubmitting] = useState(false)
    const [error, setError] = useAuthError()
    const { handleSubmit, control } = useForm<LoginFormTypes>()

    const onSubmit: SubmitHandler<LoginFormTypes> = async ({
        username,
        password
    }) => {
        setSubmitting(true)
        try {
            await login({ username, password })
            enqueueSnackbar('Login success', {
                variant: 'success',
                action: (key) => (
                    <MIconButton
                        size="small"
                        onClick={() => closeSnackbar(key)}
                    >
                        <Icon icon={closeFill} />
                    </MIconButton>
                )
            })
        } catch (error) {
            setError(error)
        }
    }

    return (
        <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
            <StackDefault>
                <AuthErrorAlert error={error} />

                <TextFieldElement
                    name={'username'}
                    control={control}
                    label={'Email'}
                    rules={{ required: true }}
                />

                <PasswordElement
                    name={'password'}
                    label={'Password'}
                    control={control}
                />
            </StackDefault>

            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
            >
                <CheckboxElement
                    name={'remember'}
                    label={'Remember Me'}
                    control={control}
                />

                <Link
                    component={RouterLink}
                    variant="subtitle2"
                    to={PATH_AUTH.resetPassword}
                >
                    Forgot password?
                </Link>
            </Stack>

            <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
            >
                Login
            </LoadingButton>
        </form>
    )
}
