import { OptimisticMutationResolver as GraphCacheOptimisticMutationResolver } from '@urql/exchange-graphcache/dist/types/types'

import { removeNullsOfObject } from '../../helpers/removeNullsOfObj'
import { getUserId } from '../../state/authState'
import {
    Club,
    GraphCacheConfig,
    GraphCacheOptimisticUpdaters,
    Maybe,
    Mutation_RootInsert_Payment_OneArgs,
    Payment,
    UserClubPermissionsDocument,
    UserClubPermissionsQueryVariables,
    WithTypename
} from '../__generated__/generated-schema'
import { cacheUpdateListQueries } from './helpers/cacheUpdateListQueries'
import cacheUpdateOneToMany from './helpers/cacheUpdateOneToMany'

export const urqlClubOffline: GraphCacheConfig = {
    resolvers: {
        query_root: {},
        club: {}
    },
    updates: {
        Mutation: {
            delete_club_by_pk: (parent, args, cache) => {
                if (args.id) {
                    cacheUpdateListQueries<UserClubPermissionsQueryVariables>(
                        cache,
                        {
                            fieldName: 'club',
                            query: UserClubPermissionsDocument,
                            variables: {
                                userId: getUserId() as string
                            }
                        },
                        { removeId: args.id }
                    )
                }
            },
            // update_club_by_pk: (parent, args, cache) => {
            //     // nothing to do here
            // },
            insert_payment_one: (parent, args, cache) => {
                const clubId = parent.insert_payment_one?.club_id
                const id = parent.insert_payment_one?.id
                console.log(clubId, id)
                if (clubId && id) {
                    cacheUpdateOneToMany(cache, {
                        fieldName: 'payments',
                        oneEntity: {
                            __typename: 'payment',
                            id
                        },
                        manyEntity: {
                            __typename: 'club',
                            id: clubId
                        }
                    })
                }
            },
            insert_club_one: (parent, args, cache) => {
                // we invalidate to trigger refetch
                // invalidQueries(cache, 'club')
                const ownerId =
                    args.object.owner_id || parent.insert_club_one?.owner_id
                if (!ownerId) {
                    console.warn('owner ID is missing')
                }
                console.log('insert club one:', parent.insert_club_one, args)
                cacheUpdateListQueries<UserClubPermissionsQueryVariables>(
                    cache,
                    {
                        fieldName: 'club',
                        query: UserClubPermissionsDocument,
                        variables: {
                            userId: ownerId as string
                        }
                    },
                    { insert: parent.insert_club_one }
                )
            }
        }
    },
    optimistic: {
        delete_club_by_pk: (vars) => {
            return {
                __typename: 'club',
                id: vars.id
            }
        },
        update_club_by_pk: (vars) => {
            return {
                __typename: 'club',
                id: vars.pk_columns.id,
                ...vars._set,
                updated_at: new Date().toISOString(),
                owner_id: getUserId()
            }
        },
        insert_payment_one: ({ object }) => {
            return {
                ...removeNullsOfObject(object),
                __typename: 'payment'
            }
        },
        insert_club_one: ({ object }) => {
            return {
                __typename: 'club',
                ...removeNullsOfObject(object),
                payments: [],
                created_at: new Date().toISOString(),
                updated_at: new Date().toISOString(),
                owner_id: getUserId(),
                user_permissions: []
            }
        }
    }
}
