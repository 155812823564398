import { Auth } from 'aws-amplify'
import { useCallback, useEffect, useState } from 'react'
import shallow from 'zustand/shallow'

import { useAuthState } from '../state/authState'

export default function useAmplifyAuth() {
    const [initialized, setInitialized] = useState<boolean>(false)
    const setUser = useAuthState((state) => state.setUser, shallow)

    const initial = useCallback(async () => {
        try {
            // const user = await Auth.currentUserCredentials()
            const cognitoUserSession = await Auth.currentSession()
            // const refreshToken = cognitoUserSession?.getAccessToken().getJwtToken()
            if (typeof cognitoUserSession?.getIdToken === 'function') {
                const idToken = cognitoUserSession.getIdToken()
                if (idToken) {
                    setUser({
                        email: idToken.payload.email,
                        sub: idToken.payload.sub,
                        confirmed: idToken.payload.email_verified,
                        jwtToken: idToken.getJwtToken()
                    })
                }
            }
            setInitialized(true)
        } catch (e) {
            console.warn(e)
            // unset user
            setUser(null)
            setInitialized(true)
        }
    }, [])

    useEffect(() => {
        initial()
    }, [initial])

    return initialized
}
