import {
    Cache,
    Entity,
    FieldInfo
} from '@urql/exchange-graphcache/dist/types/types'

export default function cacheUpdateOneToMany(
    cache: Cache,
    {
        fieldName,
        oneEntity,
        manyEntity
    }: {
        fieldName: string
        oneEntity: Entity
        manyEntity: Entity
    },
    isRemove?: boolean
) {
    const keyOfOne = cache.keyOfEntity(oneEntity)
    const keyOfMany = cache.keyOfEntity(manyEntity)
    let resolveOldValues = cache.resolve(keyOfMany, fieldName)

    let newRel
    if (!Array.isArray(resolveOldValues)) {
        // do nothing
        const hasID = cache.resolve(keyOfMany, 'id')
        if (!hasID) {
            console.warn('there is no array in the resolved relationship.')
            return
        }
        resolveOldValues = []
    }
    if (isRemove) {
        newRel = resolveOldValues.filter((i) => i !== keyOfOne)
    } else {
        if (resolveOldValues.find((i) => i === keyOfOne)) {
            // entry exists already
            return
        }
        newRel = [keyOfOne]
        // @ts-ignore
        newRel = [...resolveOldValues, ...newRel]
    }
    if (Array.isArray(newRel)) {
        // @ts-ignore
        cache.link(keyOfMany, fieldName, newRel)
    }
}
