import { Cache } from '@urql/exchange-graphcache/dist/types/types'
import { DocumentNode } from 'graphql'

/**
 * Use this if you know fieldName, query and variables to update query lists after
 * insert or delete
 */
export const cacheUpdateListQueries = <QueryVariables>(
    cache: Cache,
    {
        query,
        fieldName,
        variables
    }: { fieldName: string; query: DocumentNode; variables?: QueryVariables },
    { insert, removeId }: { insert?: any; removeId?: any }
) => {
    cache.updateQuery(
        {
            query: query,
            variables
        },
        (data) => {
            if (!data) {
                console.warn('There is no data available.')
            }
            if (data?.[fieldName] && Array.isArray(data[fieldName])) {
                if (insert) {
                    if (!insert.__typename || !insert.id) {
                        console.warn(
                            'Insert document missing required fields __typename or id',
                            insert
                        )
                    }
                    // @ts-ignore
                    data[fieldName].push(insert)
                }
                if (removeId) {
                    // @ts-ignore
                    data[fieldName] = data[fieldName].filter(
                        (i: any) => i.id !== removeId
                    )
                }
            } else {
                console.warn('cached data is not an array', data)
            }
            return data
        }
    )
}
