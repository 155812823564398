import { Alert } from '@mui/material'
import { useTranslation } from 'react-i18next'

type AuthAlertProps = {
    error?: {
        code: string
        message: string
    }
}

export default function AuthErrorAlert({ error }: AuthAlertProps) {
    const { t, i18n } = useTranslation()
    if (!error) {
        return null
    }
    const transCode = `errors.auth.${error.code}`
    const isTranslated = i18n.exists(transCode)
    return (
        <Alert severity={'error'}>
            {isTranslated ? t(transCode) : error.message}
        </Alert>
    )
}
