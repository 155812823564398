// material
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles'
import { FC, ReactNode, Suspense } from 'react'

// hooks
import useLocales from '../hooks/useLocales'
import LoadingScreen from './LoadingScreen'

// ----------------------------------------------------------------------

type ThemeLocalizationProps = {
  children: ReactNode
}

const ThemeLocalization: FC = ({ children }) => {
  const defaultTheme = useTheme()
  const { currentLang } = useLocales()

  const theme = createTheme(defaultTheme, currentLang.systemValue)

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

const ThemeLoc: FC = ({ children }) => {
  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            top: 0,
            left: 0,
            width: 1,
            zIndex: 9999,
            position: 'fixed'
          }}
        />
      }
    >
      <ThemeLocalization>{children}</ThemeLocalization>
    </Suspense>
  )
}
export default ThemeLoc
