import {
    Box,
    Card,
    Container,
    Link,
    Stack,
    Tooltip,
    Typography
} from '@mui/material'
// material
import { styled } from '@mui/material/styles'
import { capitalCase } from 'change-case'
import { Link as RouterLink } from 'react-router-dom'

import LoginForm from '../../components/authentication/login/LoginForm'
import { MHidden } from '../../minimal_theme/components/@material-extend'
// components
import Page from '../../minimal_theme/components/Page'
// hooks
// import useAuth from '../../hooks/useAuth'
// layouts
import AuthLayout from '../../minimal_theme/layouts/AuthLayout'
// routes
import { PATH_AUTH } from '../../routes/paths'

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex'
    }
}))

const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 2, 2)
}))

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(12, 0)
}))

// ----------------------------------------------------------------------

export default function Login() {
    const method = 'cognito'

    return (
        <RootStyle title="Login | Minimal-UI">
            <AuthLayout>
                Don’t have an account? &nbsp;
                <Link
                    underline="none"
                    variant="subtitle2"
                    component={RouterLink}
                    to={PATH_AUTH.register}
                >
                    Get started
                </Link>
            </AuthLayout>

            <MHidden width="mdDown">
                <SectionStyle>
                    <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                        Hi, Welcome Back
                    </Typography>
                    <img
                        src="/static/illustrations/illustration_login.png"
                        alt="login"
                    />
                </SectionStyle>
            </MHidden>

            <Container maxWidth="sm">
                <ContentStyle>
                    <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
                        <Box sx={{ flexGrow: 1 }}>
                            <Typography variant="h4" gutterBottom>
                                Sign in to planet.training
                            </Typography>
                            <Typography sx={{ color: 'text.secondary' }}>
                                Enter your details below.
                            </Typography>
                        </Box>

                        <Tooltip title={capitalCase(method)}>
                            <Box
                                component="img"
                                src={`/static/auth/ic_${method}.png`}
                                sx={{ width: 32, height: 32 }}
                            />
                        </Tooltip>
                    </Stack>

                    <LoginForm />

                    <MHidden width="smUp">
                        <Typography
                            variant="body2"
                            align="center"
                            sx={{ mt: 3 }}
                        >
                            Don’t have an account?&nbsp;
                            <Link
                                variant="subtitle2"
                                component={RouterLink}
                                to={PATH_AUTH.register}
                            >
                                Get started
                            </Link>
                        </Typography>
                    </MHidden>
                </ContentStyle>
            </Container>
        </RootStyle>
    )
}
