// routes

// components

import { SplashScreen } from '@capacitor/splash-screen'
import { useEffect } from 'react'

import ReloadPrompt from './components/generic/ReloadPrompt'
import useAmplifyAuth from './lib/aws/useAmplifyAuth'
import UrqlProvider from './lib/urql/UrqlProvider'
// import GoogleAnalytics from './minimal_theme/components/GoogleAnalytics'
// <<<<<<< HEAD
// import LoadingScreen, {
// =======
import { ProgressBarStyle } from './minimal_theme/components/LoadingScreen'
import NotistackProvider from './minimal_theme/components/NotistackProvider'
import ScrollToTop from './minimal_theme/components/ScrollToTop'
import ThemeLocalization from './minimal_theme/components/ThemeLocalization'
import ThemePrimaryColor from './minimal_theme/components/ThemePrimaryColor'
// hooks
// theme
import ThemeConfig from './minimal_theme/theme'
import GlobalStyles from './minimal_theme/theme/globalStyles'
import Router from './routes/routes'

export default function AppShell() {
    const isInitialized = useAmplifyAuth()

    useEffect(() => {
        if (isInitialized) {
            SplashScreen.hide().then((r) => {
                console.log('Splash screen hidden', r)
            })
        } else {
            SplashScreen.show({
                autoHide: false
            }).then((r) => {
                console.log(r)
            })
        }
    }, [isInitialized])

    // const isInitialized = true
    return (
        <ThemeConfig>
            <ThemePrimaryColor>
                <ThemeLocalization>
                    <NotistackProvider>
                        <UrqlProvider>
                            <GlobalStyles />
                            <ProgressBarStyle />
                            <ScrollToTop />
                            {/*<GoogleAnalytics />*/}
                            {/*{isInitialized ? <Router /> : <LoadingScreen />}*/}
                            {isInitialized ? <Router /> : <div />}
                            <ReloadPrompt />
                        </UrqlProvider>
                    </NotistackProvider>
                </ThemeLocalization>
            </ThemePrimaryColor>
        </ThemeConfig>
    )
}
