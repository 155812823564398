import { FC, useEffect } from 'react'
import shallow from 'zustand/shallow'

import { useAuthState } from '../../lib/state/authState'
import { useUserClubPermissionsQuery } from '../../lib/urql/__generated__/generated-schema'
import LoadingScreen from '../../minimal_theme/components/LoadingScreen'

const UserClubSubscribe: FC = ({ children }) => {
    const [setClubs, user] = useAuthState(
        (state) => [state.setClubs, state.user],
        shallow
    )
    // const [resultSub] = useUserPermissionSubscription({})
    const [{ data, error, fetching }] = useUserClubPermissionsQuery({
        variables: {
            userId: user?.sub as string
        },
        pause: !user?.sub
    })
    if (error) {
        console.error(error)
    }
    const clubs = data?.club
    useEffect(() => {
        if (clubs && !fetching) {
            console.log('setClubs', clubs, fetching)
            setClubs(clubs)
        }
    }, [clubs, setClubs, fetching])

    if (fetching) {
        return <LoadingScreen />
    }

    return <>{children}</>
}

export default UserClubSubscribe
