import { offlineExchange } from '@urql/exchange-graphcache'
import { makeDefaultStorage } from '@urql/exchange-graphcache/default-storage'

import schema from './__generated__/generated-introspection'
import { GraphCacheConfig } from './__generated__/generated-schema'
import { urqlOfflineCache } from './offline'

const storage = makeDefaultStorage({
    idbName: 'ptCache', // The name of the IndexedDB database
    maxAge: 7 // The maximum age of the persisted data in days
})

export const offlineCache = offlineExchange({
    schema,
    storage,
    ...urqlOfflineCache
})
