import { removeNullsOfObject } from '../../helpers/removeNullsOfObj'
import { getUserId } from '../../state/authState'
import {
    AllExercisesDocument,
    GraphCacheConfig
} from '../__generated__/generated-schema'
import { cacheUpdateListQueries } from './helpers/cacheUpdateListQueries'
import cacheUpdateOneToMany from './helpers/cacheUpdateOneToMany'

export const urqlExerciseOffline: GraphCacheConfig = {
    updates: {
        Mutation: {
            insert_exercise_one: (parent, args, cache) => {
                const exerciseId: string = parent.insert_exercise_one
                    ?.id as string
                const insertExerciseOne = parent.insert_exercise_one

                cacheUpdateListQueries(
                    cache,
                    {
                        query: AllExercisesDocument,
                        fieldName: 'exercise'
                    },
                    {
                        insert: insertExerciseOne
                    }
                )
                // because we redirect we need to link the exercise_by_pk
                cache.link(
                    'query_root',
                    'exercise_by_pk',
                    {
                        id: exerciseId
                    },
                    {
                        __typename: 'exercise',
                        id: exerciseId
                    }
                )
            },
            update_exercise_by_pk: (/* parent, args, cache */) => {
                // console.log('update exercise pk', args)
            },
            insert_exercise_content_media_one: (parent, args, cache) => {
                console.log('inside insert_exercise_content_media_one', parent)
            },
            insert_exercise_club_mn: (parent, args, cache, info) => {
                // we need to set new set of array on the relation
                const exerciseId = info.variables.id
                if (exerciseId) {
                    cache.link(
                        {
                            __typename: 'exercise',
                            id: exerciseId as string
                        },
                        'exercise_club_mns',
                        args.objects.map((item) => ({
                            ...item,
                            __typename: 'exercise_club_mn'
                        }))
                    )
                } else {
                    console.warn('exercise ID is missing')
                }
            },
            insert_exercise_taxonomy_mn: (parent, args, cache, info) => {
                const exerciseId = info.variables.id
                if (exerciseId) {
                    cache.link(
                        {
                            __typename: 'exercise',
                            id: exerciseId as string
                        },
                        'exercise_taxonomy_mns',
                        args.objects.map((item) => {
                            return {
                                ...item,
                                __typename: 'exercise_taxonomy_mn'
                            }
                        })
                    )
                } else {
                    console.warn('exercise ID is missing')
                }
            },
            insert_exercise_content_media_mn_one: (parent, args, cache) => {
                const mn = parent.insert_exercise_content_media_mn_one
                const { exercise_content_media_id, exercise_id } = mn || {}
                if (exercise_content_media_id && exercise_id) {
                    const oneEntity = {
                        __typename: 'exercise_content_media_mn',
                        exercise_id: exercise_id,
                        exercise_content_media_id: exercise_content_media_id
                    }
                    // update mns on exercise
                    const fieldName = 'exercise_content_media_mns'

                    const manyEntity = {
                        __typename: 'exercise',
                        id: exercise_id
                    }
                    cache
                        .inspectFields(manyEntity)
                        .filter((field) => field.fieldName === fieldName)
                        .forEach((field) => {
                            console.log(field)
                            cacheUpdateOneToMany(cache, {
                                fieldName: field.fieldKey,
                                oneEntity: oneEntity,
                                manyEntity: manyEntity
                            })
                        })

                    cache.link(oneEntity, 'exercise_content_media', {
                        __typename: 'exercise_content_media',
                        id: exercise_content_media_id
                    })
                }
            },
            delete_exercise_content_media_mn_by_pk: (parent, args, cache) => {
                const { exercise_content_media_id, exercise_id } = args || {}
                if (exercise_id && exercise_content_media_id) {
                    const manyEntity = {
                        __typename: 'exercise',
                        id: exercise_id
                    }
                    const oneEntity = {
                        __typename: 'exercise_content_media_mn',
                        exercise_id: exercise_id,
                        exercise_content_media_id: exercise_content_media_id
                    }
                    const fieldName = 'exercise_content_media_mns'

                    cache
                        .inspectFields(manyEntity)
                        .filter((field) => field.fieldName === fieldName)
                        .forEach((field) => {
                            cacheUpdateOneToMany(
                                cache,
                                {
                                    fieldName: field.fieldKey,
                                    oneEntity: oneEntity,
                                    manyEntity: manyEntity
                                },
                                true
                            )
                        })
                }
            }
        }
    },
    keys: {
        exercise_club_mn: (data) => `${data.exercise_id};${data.club_id}`,
        exercise_content_media_mn: (data) =>
            `${data.exercise_id};${data.exercise_content_media_id}`,
        exercise_taxonomy_mn: (data) =>
            `${data.exercise_id};${data.taxonomy_id}`
    },
    optimistic: {
        insert_exercise_club_mn: ({ objects }) => {
            return {
                ...removeNullsOfObject(objects),
                __typename: 'exercise_club_mn_mutation_response'
            }
        },
        insert_exercise_club_mn_one: (vars) => {
            console.log('inside of exercise mn one', vars)
            return {
                ...removeNullsOfObject(vars.object),
                __typename: 'exercise_club_mn'
            }
        },
        insert_exercise_taxonomy_mn: ({ objects }) => {
            return {
                ...removeNullsOfObject(objects),
                __typename: 'exercise_taxonomy_mn_mutation_response'
            }
        },
        delete_exercise_taxonomy_mn: (vars) => {
            return {
                ...vars,
                __typename: 'exercise_taxonomy_mn_mutation_response'
            }
        },
        delete_exercise_content_media_mn_by_pk: (vars) => {
            return {
                ...vars,
                __typename: 'exercise_content_media_mn'
            }
        },
        insert_exercise_content_media_one: ({ object }) => {
            return {
                ...removeNullsOfObject(object),
                is_media_right: false,
                created_at: new Date().toISOString(),
                updated_at: new Date().toISOString(),
                __typename: 'exercise_content_media'
            }
        },
        insert_exercise_content_media_mn_one: ({ object }) => {
            return {
                ...removeNullsOfObject(object),
                __typename: 'exercise_content_media_mn'
            }
        },
        insert_exercise_one: ({ object }) => {
            return {
                ...removeNullsOfObject(object),
                __typename: 'exercise',
                created_at: new Date().toISOString(),
                updated_at: new Date().toISOString(),
                owner_id: object.owner_id || getUserId()
            }
        },
        update_exercise_by_pk: ({ _set, pk_columns }) => {
            const id = pk_columns.id

            return {
                __typename: 'exercise',
                ..._set,
                id,
                updated_at: new Date().toISOString(),
                owner_id: getUserId()
            }
        }
    }
}
