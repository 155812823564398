import { CognitoUser } from 'amazon-cognito-identity-js'
import { Auth } from 'aws-amplify'
import create from 'zustand'

import {
    Enum_Sport_Enum,
    License_Enum,
    User_Permission_Role_Enum,
    UserClubFragment
} from '../urql/__generated__/generated-schema'
import { useClubState } from './clubState'

type UserCredentials = {
    username: string
    password: string
}

export type ClubPermission = {
    id: string
    name: string
    sport: Enum_Sport_Enum
    isAdmin: boolean
    isOwner: boolean
    licences?: License_Enum[]
    permissions: User_Permission_Role_Enum[]
}

type ClubPermissionAlternative = {
    [k: string]: ClubPermission
}

type AuthUser = {
    username: string
    sub: string
    first_name?: string
    last_name?: string
    confirmed?: boolean
    jwtToken?: string
    photoURL?: string
    displayName?: string
}

type UseAuthState = {
    user: Partial<AuthUser>
    clubs?: UserClubFragment[]
    clubPermission: Partial<ClubPermission>
    alternativeClubPermission: ClubPermissionAlternative
    login: (credentials: UserCredentials) => Promise<void>
    register: (credentials: UserCredentials) => Promise<void>
    signOut: () => Promise<void>
    setUser: (user: Partial<AuthUser>) => void
    setClubs: (clubs: UserClubFragment[]) => void
    getClubPermissionsOnId: (clubId: string) => ClubPermission | undefined
    getClubs: (
        lookup?: User_Permission_Role_Enum[],
        options?: { onActiveOnly?: boolean }
    ) => ClubPermission[]
    forgotPassword: (email: string) => Promise<void>
    resendSignUp: () => Promise<void>
    confirmSignUp: (code: string) => Promise<void>
    forgotPasswordSubmit: (data: {
        username: string
        password: string
        code: string
    }) => Promise<void>
}

type TempUser = Omit<AuthUser, 'sub' | 'jwtToken' | 'first_name' | 'last_name'>

const getTempUser = (): TempUser | null => {
    const user = localStorage.getItem('temp_user')
    if (user) {
        return JSON.parse(user)
    }
    return null
}
const setTempUser = (user: TempUser | null) => {
    if (user?.username) {
        localStorage.setItem('temp_user', JSON.stringify(user))
    } else {
        localStorage.removeItem('temp_user')
    }
}

const signInUser = async ({ username, password }: UserCredentials) => {
    const cognitoUser: CognitoUser = await Auth.signIn(username, password)
    const idToken = cognitoUser?.getSignInUserSession()?.getIdToken()

    if (idToken) {
        const user: AuthUser = {
            jwtToken: idToken.getJwtToken(),
            sub: idToken.payload.sub,
            username: idToken.payload.email,
            confirmed: idToken.payload.email_verified
        }
        return user
    }
    throw Error('id token does not exist')
}

export const useAuthState = create<UseAuthState>((set, get) => ({
    user: {},
    clubPermission: {},
    alternativeClubPermission: {},
    setUser: (user) => set({ user: user }),
    setClubs: (clubs) => {
        const alternativeClubPermission: ClubPermissionAlternative = {}
        const userId = get().user.sub
        const currentClubId = useClubState.getState().selectedClub?.id
        clubs.forEach((club) => {
            const isOwner = club.owner_id === userId
            const activeClub = currentClubId === club.id
            const activeLicenses: License_Enum[] = (club.payments || [])
                .map((payment) => {
                    const isActive =
                        payment.active ||
                        (payment.valid_until &&
                            new Date() <= new Date(payment.valid_until))
                    if (isActive) {
                        if (payment.is_trial) {
                            return License_Enum.Trial
                        }
                        return payment.license
                    }
                })
                .filter((i) => i) as License_Enum[]

            const currentClubPermission: ClubPermission = {
                id: club.id,
                name: club.name,
                sport: club.sport,
                isOwner: isOwner,
                isAdmin:
                    isOwner ||
                    club.user_permissions.some(
                        (i) => i.role === User_Permission_Role_Enum.ClubAdmin
                    ),
                licences: activeLicenses,
                permissions: club.user_permissions?.map((i) => i.role) || []
            }
            if (activeClub) {
                set({ clubPermission: currentClubPermission })
            } else {
                alternativeClubPermission[club.id] = currentClubPermission
            }
        })
        set({ alternativeClubPermission, clubs })
    },
    getClubPermissionsOnId: (clubId) => {
        return get().alternativeClubPermission?.[clubId]
    },
    getClubs: (lookup, options) => {
        const altClubs = get().alternativeClubPermission
        const alternativeClubs = Object.keys(altClubs).map((i) => altClubs[i])
        const permittedClubs: ClubPermission[] = [
            get().clubPermission,
            ...alternativeClubs
        ].filter((i) => i?.id) as ClubPermission[]
        // some filter?
        if (lookup) {
            return permittedClubs.filter((c) => {
                if (options?.onActiveOnly && !c.licences?.length) {
                    return false
                }
                return c.permissions.some((i) => lookup.includes(i))
            })
        }
        return permittedClubs
    },
    login: async ({ username, password }) => {
        try {
            const user = await signInUser({ username, password })
            setTempUser(null)
            set({ user })
        } catch (error: any) {
            if (error?.code === 'UserNotConfirmedException') {
                setTempUser({ username })
                set({ user: { username, sub: '' } })
            } else {
                throw error
            }
        }
    },
    register: async ({ password, ...data }) => {
        const { userSub, userConfirmed } = await Auth.signUp({
            username: data.username,
            password: password,
            attributes: {}
        })
        setTempUser(data)
        set({
            user: {
                ...data,
                sub: userSub,
                confirmed: userConfirmed
            }
        })
    },
    signOut: async () => {
        set({ user: {} })
        await Auth.signOut()
    },
    forgotPassword: async (email) => {
        await Auth.forgotPassword(email)
    },
    forgotPasswordSubmit: async ({ password, username, code }) => {
        const forgot = await Auth.forgotPasswordSubmit(username, code, password)
        const user = await signInUser({ username, password })
        console.log(forgot, user)
        set({ user })
    },
    resendSignUp: async () => {
        const username = getTempUser()?.username
        if (username) {
            await Auth.resendSignUp(username)
        }
    },
    confirmSignUp: async (code) => {
        const username = getTempUser()?.username || get().user?.username
        if (username) {
            await Auth.confirmSignUp(username, code)
            setTempUser(null)
            set({
                user: {
                    username: '',
                    sub: '',
                    confirmed: true
                }
            })
        }
    }
}))

export const getUserId = () => {
    return useAuthState.getState().user.sub || ''
}
